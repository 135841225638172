<template>
  <div v-if="show" class="modal-overlay" @click.self="close">
    <div class="modal" v-if="isBanned">
      <div class="modal-title error">
        Уважаемый клиент. К сожалению, из-за многочисленных отмен вы находитесь
        в списке контактов, которым доступна запись по предоплате. Свяжитесь с
        нами по телефону или смс 8(919)576-00-69 <br /><br />
        С уважением, руководитель студии «Леон»
      </div>
    </div>

    <div class="modal" v-else-if="thanks">
      <div class="modal-title">Спасибо за запись</div>
    </div>

    <div class="modal" v-if="!isBanned && !thanks">
      <div class="modal-header">
        <div class="modal-title">Контактная информация</div>
      </div>
      <div class="modal-body">
        <form @submit.prevent="submitForm">
          <div v-if="!otpRequested">
            <div class="form__group field">
              <input type="text" class="form__field" placeholder="Имя" required v-model="form.firstName"
                id="firstName" />
              <label for="firstName" class="form__label">Имя</label>
            </div>
            <div class="form__group field">
              <input type="text" class="form__field" placeholder="Фамилия" required v-model="form.lastName"
                id="lastName" />
              <label for="lastName" class="form__label">Фамилия</label>
            </div>
            <div class="form__group field">
              <InputMask type="tel" class="form__field" placeholder="Телефон" required v-model="form.phone" id="phone"
                mask="+99999999999" @focus="handleFocus" />
              <label for="phone" class="form__label">Телефон</label>
            </div>
            <div class="input-group">
              <select id="service" v-model="form.service" required>
                <option disabled value="">Выберите услугу</option>
                <option v-for="service in services" :key="service.id" :value="service.name">
                  {{ service.name }}
                </option>
              </select>
            </div>
            <ButtonComponent :name="'Записаться'" :type="'submit'" />
          </div>
          <div v-else>
            <div class="">
              <p class="otp">Отправили код на телефон</p>
              <div class="otp-code">
                <InputOtp v-model="otp" integerOnly />
              </div>
            </div>
            <ButtonComponent :name="'Подтвердить'" :type="'submit'" @click="verifyOtp" />
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import ButtonComponent from "@/components/ButtonComponent.vue";
import InputMask from "primevue/inputmask";
import InputOtp from "primevue/inputotp";
import axios from "axios";
import qs from "qs";

import moment from "moment-timezone";

export default {
  components: {
    ButtonComponent,
    InputMask,
    InputOtp,
  },

  name: "Modal",
  props: {
    show: {
      type: Boolean,
      required: true,
    },
    master: {
      type: Object,
      required: false,
      default: null,
    },
    date: {
      type: String,
      required: false,
      default: "",
    },
    time: {
      type: String,
      required: false,
      default: "",
    },
  },
  data() {
    return {
      form: {
        firstName: "",
        lastName: "",
        phone: "",
        service: "",
      },
      services: [],
      open: false,
      thanks: false,
      otpRequested: false,
      email: "",
      otp: "",
      isBanned: false,
    };
  },
  methods: {
    close() {
      this.$emit("close");
    },
    submitForm() {
      if (!this.otpRequested) {
        this.requestOtp();
      }
    },
    normalizePhoneNumber(phone) {
      if (phone.startsWith("+8")) {
        return phone.replace("+8", "+7");
      } else if (phone.startsWith("8")) {
        return phone.replace("8", "+7");
      }
      return phone;
    },
    requestOtp() {
      const normalizedPhone = this.normalizePhoneNumber(this.form.phone);
      axios
        .post("https://leon-back-hiss.amvera.io/sms/send", null, {
          params: { phone: normalizedPhone },
          paramsSerializer: (params) => {
            return qs.stringify(params, { encode: false });
          },
        })
        .then(() => {
          this.otpRequested = true;
        })
        .catch((error) => {
          // console.error("Error requesting OTP:", error);
          if (
            error.response.data == "Телефон клиента находится в чёрном списке"
          ) {
            this.isBanned = true;
          }
        });
    },
    verifyOtp() {
      const normalizedPhone = this.normalizePhoneNumber(this.form.phone);
      axios
        .post("https://leon-back-hiss.amvera.io/sms/validate", null, {
          // null в теле запроса
          params: {
            phone: normalizedPhone,
            otp: this.otp,
          },
          paramsSerializer: (params) => {
            // Добавляем paramsSerializer
            return qs.stringify(params, { encode: false });
          },
        })
        .then(() => {
          this.submitAppointment();
        })
        .catch((error) => {
          console.error("Invalid OTP:", error);
        });
    },
    submitAppointment() {
      // Преобразование даты в Курганское время (UTC+5)
      const formattedDate = moment
        .tz(this.date, "Asia/Yekaterinburg")
        .format("YYYY-MM-DD");

      const appointmentData = {
        date: formattedDate,
        time: this.time,
        clientName: `${this.form.firstName} ${this.form.lastName}`,
        service: this.form.service,
        clientPhone: this.form.phone,
        master: {
          id: this.master.id,
        },
      };

      console.log("Submitting appointment data:", appointmentData);

      axios
        .post("https://leon-back-hiss.amvera.io/appointment", appointmentData)
        .then((response) => {
          // console.log(response.data);
          this.thanks = true;
          setTimeout(() => {
            this.close();
          }, 2000); // Закрыть через 2 секунды
        })
        .catch((error) => {
          console.error("Error submitting appointment:", error);
        });
    },
    getService() {
      axios
        .get("https://leon-back-hiss.amvera.io/service")
        .then((response) => {
          if (this.master && this.master.id !== 2) {
            this.services = response.data.filter(
              (service) => service.name !== "Ручная полировка"
            );
          } else {
            this.services = response.data;
          }
        })
        .catch((error) => {
          console.error("Error fetching services:", error);
        });
    },
    handleFocus(event) {
      const input = event.target;
      const value = input.value;

      // Если значение пустое, устанавливаем курсор после кода страны
      if (!value) {
        input.setSelectionRange(4, 4);
      }
    },
  },
  mounted() {
    this.getService();
  },
};
</script>

<style scoped>
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
}

.modal {
  background: #1a1a1a;
  padding: 20px;
  border-radius: 8px;
  width: 90%;
  max-width: 800px;
  max-height: 90%;
  overflow-y: auto;
  animation: fadeIn 0.3s ease-out;
}

.modal-header {
  text-align: center;
}

.modal-title {
  font-size: 2em;
  color: #f4bc83;
  margin: 30px 0;
}

.error {
  font-size: 1.3rem !important;
}

.form__group {
  position: relative;
  padding: 15px 0;
  width: 100%;
}

.form__field {
  font-family: inherit;
  width: 100%;
  border: none;
  border-bottom: 2px solid #9b9b9b;
  outline: 0;
  font-size: 1em;
  color: #fff;
  padding: 7px 0;
  background: transparent;
  transition: border-color 0.2s;
}

.form__field::placeholder {
  color: transparent;
}

.form__field:placeholder-shown~.form__label {
  font-size: 1em;
  cursor: text;
  top: 20px;
}

.form__label {
  position: absolute;
  top: 0;
  display: block;
  transition: 0.2s;
  font-size: 1em;
  color: #9b9b9b;
  pointer-events: none;
}

.form__field:focus {
  padding-bottom: 6px;
  font-weight: 700;
  border-width: 3px;
  border-image: linear-gradient(to right, #f4bc83, #f4bc83);
  border-image-slice: 1;
}

.form__field:focus~.form__label {
  position: absolute;
  top: 0;
  display: block;
  transition: 0.2s;
  font-size: 1em;
  color: #f4bc83;
  font-weight: 700;
}

.form__field:required,
.form__field:invalid {
  box-shadow: none;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.input-group {
  margin: 20px 0;
}

.otp {
  font-size: 20px;
}

.otp-code {
  display: grid;
  justify-content: center;
  padding: 20px 0;
}

select {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #2a2a2a;
  color: #fff;
  appearance: none;
}

select:focus {
  border-color: #f4bc83;
  outline: none;
}

option {
  background-color: #2a2a2a;
  color: #fff;
}

option:checked,
option:hover {
  background-color: #f4bc83;
}

/* .input-group::after {
  content: "\25BC";
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  pointer-events: none;
  color: #fff;
  font-size: 12px;
} */

.select-wrapper {
  position: relative;
}

.select-wrapper select {
  display: block;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.p-inputtext {
  max-width: 100%;
}

@media screen and (min-width: 768px) {
  .modal {
    /* width: 60%; */
    max-width: 1000px;
  }

  .modal-title {
    font-size: 2.5em;
  }
}

@media screen and (min-width: 1024px) {
  .modal {
    /* width: 50%; */
    max-width: 600px;
  }

  .modal-title {
    /* font-size: 3.5em; */
  }
}
</style>
