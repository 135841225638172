<template>
  <div>
    <h1 style="cursor: pointer" @click="openForm = !openForm">
      Создать мастера
    </h1>
    <div class="create-master" v-if="openForm">
      <form @submit.prevent="create">
        <div class="form__group field">
          <input type="text" class="form__field" placeholder="Имя Мастер" required v-model="firstName" id="firstName" />
          <label for="firstName" class="form__label">Имя</label>
        </div>

        <div class="form__group field">
          <input type="text" class="form__field" placeholder="Фамилия Мастера" required v-model="lastName"
            id="lastName" />
          <label for="lastName" class="form__label">Фамилия</label>
        </div>

        <div class="form__group field">
          <InputMask style="max-width: 100%" type="tel" class="form__field" placeholder="Телефон" required
            v-model="phone" id="phone" mask="+79999999999" @focus="handleFocus" />
          <label for="phone" class="form__label">Номер телефона</label>
        </div>

        <div class="form__group field">
          <input type="text" class="form__field" placeholder="Телегам" v-model="telegram" id="telegram" />
          <label for="telegram" class="form__label">Телегам</label>
        </div>

        <div class="form__group field">
          <input type="text" class="form__field" placeholder="Инстаграм" v-model="inst" id="inst" />
          <label for="inst" class="form__label">Инстаграм</label>
        </div>

        <div class="form__group field">
          <input type="text" class="form__field" placeholder="Должность" required v-model="post" id="post" />
          <label for="post" class="form__label">Должность</label>
        </div>

        <div class="form__group field">
          <input type="text" class="form__field" placeholder="Логин" required v-model="userName" id="userName" />
          <label for="userName" class="form__label">Логин</label>
        </div>

        <div class="form__group field">
          <input type="password" class="form__field" placeholder="Пароль" required v-model="password" id="password" />
          <label for="password" class="form__label">Пароль</label>
        </div>

        <div class="form__group field">
          <input type="file" @change="onFileSelect" required />
        </div>

        <ButtonComponent :name="'Создать'" :type="submit" style="margin-top: 20px" />
      </form>
    </div>
    <div class="all-masters-container">
      <h1>Все мастера</h1>
      <div class="all-masters">
        <div class="master" v-for="master in masters" :key="master.id">
          <div class="master-img-container">
            <img class="master-img" :src="'data:image/png;base64,' + master?.image" alt="" />
          </div>
          <div class="master-info">
            <div class="master-text">ID: {{ master.id }}</div>
            <div class="master-text">Имя: {{ master.firstName }}</div>
            <div class="master-text">Фамилия: {{ master.lastName }}</div>
            <div class="master-text">Телефон: {{ master.phone }}</div>
            <div class="master-text">Должность: {{ master.post }}</div>
            <div class="master-text">Телегам: {{ master.telegram }}</div>
            <div class="master-text">Инстаграм: {{ master.inst }}</div>
            <div class="master-text">Роль: {{ master.role }}</div>
            <div class="master-text">Логин: {{ master.username }}</div>
            <!-- <div class="master-text">Пароль: {{ master.password }}</div> -->
          </div>
          <div class="master-text">
            <ButtonComponent :name="'Удалить'" :type="button" @click="deleteMaster(master.id)" />
            <div class="edit-master">
              <svg @click="editMaster(master)" id="svg" fill="#000000" stroke="#000000" width="200px" height="200px"
                version="1.1" viewBox="144 144 512 512" xmlns="http://www.w3.org/2000/svg"
                data-darkreader-inline-fill="" data-darkreader-inline-stroke="" style="
                  --darkreader-inline-fill: #000000;
                  --darkreader-inline-stroke: #e8e6e3;
                ">
                <g id="IconSvg_bgCarrier" stroke-width="0"></g>
                <g id="IconSvg_tracerCarrier" stroke-linecap="round" stroke-linejoin="round" stroke="#CCCCCC"
                  data-darkreader-inline-stroke="" style="--darkreader-inline-stroke: #c8c3bc"></g>
                <g id="IconSvg_iconCarrier">
                  <path xmlns="http://www.w3.org/2000/svg"
                    d="m636.16 368.51v188.93c0 20.879-8.293 40.902-23.059 55.664-14.762 14.766-34.785 23.059-55.664 23.059h-314.88c-20.879 0-40.902-8.293-55.664-23.059-14.762-14.762-23.059-34.785-23.059-55.664v-314.88c0-20.879 8.2969-40.902 23.059-55.664s34.785-23.059 55.664-23.059h188.93c5.625 0 10.82 3.0039 13.633 7.875 2.8125 4.8711 2.8125 10.871 0 15.742s-8.0078 7.8711-13.633 7.8711h-188.93c-12.527 0-24.539 4.9766-33.398 13.836s-13.836 20.871-13.836 33.398v314.88c0 12.527 4.9766 24.543 13.836 33.398 8.8594 8.8594 20.871 13.836 33.398 13.836h314.88c12.527 0 24.543-4.9766 33.398-13.836 8.8594-8.8555 13.836-20.871 13.836-33.398v-188.93c0-5.625 3-10.824 7.8711-13.637 4.8711-2.8125 10.871-2.8125 15.746 0 4.8711 2.8125 7.8711 8.0117 7.8711 13.637zm-333.62 41.25 234.59-234.59v-0.003906c7.4062-7.0547 17.246-10.988 27.473-10.988 10.23 0 20.066 3.9336 27.473 10.988l32.75 32.75c7.2773 7.2891 11.363 17.172 11.363 27.473s-4.0859 20.184-11.363 27.473l-234.59 234.59c-2.5156 2.4688-5.7891 4.0195-9.2891 4.4102l-73.684 8.3438h-1.7305c-4.4961 0.027344-8.7852-1.8672-11.793-5.207-3.0078-3.3359-4.4453-7.8008-3.9531-12.27l8.1875-73.684c0.43359-3.5195 2.0391-6.793 4.5664-9.2852zm256.79-212.23-20.938 20.781 43.297 43.297 20.781-20.941c1.4453-1.3398 2.2656-3.2227 2.2656-5.1953s-0.82031-3.8555-2.2656-5.1953l-32.75-32.746c-1.3633-1.4062-3.2383-2.1992-5.1953-2.1992-1.957 0-3.832 0.79297-5.1953 2.1992zm-236.16 279.14 48.648-5.3516 187.51-187.51-43.137-43.141-187.51 187.36z">
                  </path>
                </g>
              </svg>
            </div>
          </div>
        </div>
      </div>
    </div>
    <edit-master-modal :visible="isModalVisible" :master="selectedMaster" @close="closeModal" @save="updateMaster" />
  </div>
  <ModalInfo v-if="isModalVisibleInfo" :message="modalMessage" @close="isModalVisibleInfo = false" />
</template>

<script>
import axios from "axios";
import ButtonComponent from "@/components/ButtonComponent.vue";
import InputMask from "primevue/inputmask";
import FileUpload from "primevue/fileupload";
import EditMasterModal from "@/components/EditMasterModal.vue";
import ModalInfo from "@/components/ModalInfo.vue";

export default {
  components: {
    ButtonComponent,
    InputMask,
    FileUpload,
    EditMasterModal,
    ModalInfo,
  },

  data() {
    return {
      firstName: "",
      lastName: "",
      phone: "",
      telegram: "",
      inst: "",
      post: "",
      userName: "",
      password: "",
      selectedFile: null,
      error: "",
      openForm: false,
      masters: [],
      selectedMaster: null,
      isModalVisible: false,
      isModalVisibleInfo: false,
      modalMessage: "",
    };
  },
  methods: {
    onFileSelect(event) {
      this.selectedFile = event.target.files[0];
    },
    async create() {
      const formData = new FormData();
      formData.append("firstName", this.firstName);
      formData.append("lastName", this.lastName);
      formData.append("phone", this.phone);
      formData.append("telegram", this.telegram);
      formData.append("inst", this.inst);
      formData.append("post", this.post);
      formData.append("userName", this.userName);
      formData.append("password", this.password);
      if (this.selectedFile) {
        formData.append("file", this.selectedFile);
      }

      const token = localStorage.getItem("token");
      const headers = {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      };

      try {
        const response = await axios.post(
          "https://leon-back-hiss.amvera.io/masters",
          formData,
          { headers }
        );
        this.masters.push(response.data);
        this.showModal("Данные успешно сохранены");
      } catch (error) {
        this.error = error.message;
        this.showModal("Ошибка при отправке данных");
      }
    },
    handleFocus(event) {
      const input = event.target;
      const value = input.value;

      // Если значение пустое, устанавливаем курсор после кода страны
      if (!value) {
        input.setSelectionRange(4, 4);
      }
    },
    getMasters() {
      const token = localStorage.getItem("token");
      const headers = {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      };

      axios
        .get("https://leon-back-hiss.amvera.io/masters/admin", { headers })
        .then((response) => {
          this.masters = response.data;
        })
        .catch((error) => console.log(error));
    },
    deleteMaster(id) {
      const token = localStorage.getItem("token");
      const headers = {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      };
      axios
        .delete("https://leon-back-hiss.amvera.io/masters/admin/" + id, {
          headers,
        })
        .then((response) => {
          this.masters = this.masters.filter((master) => master.id !== id);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    editMaster(master) {
      this.selectedMaster = { ...master };
      this.isModalVisible = true;
    },
    closeModal() {
      this.isModalVisible = false;
      this.selectedMaster = null;
    },
    async updateMaster(updatedMaster) {
      const formData = new FormData();
      formData.append("firstName", updatedMaster.firstName);
      formData.append("lastName", updatedMaster.lastName);
      formData.append("phone", updatedMaster.phone);
      formData.append("telegram", updatedMaster.telegram);
      formData.append("inst", updatedMaster.inst);
      formData.append("post", updatedMaster.post);
      formData.append("userName", updatedMaster.userName);
      if (updatedMaster.file) {
        formData.append("image", updatedMaster.file);
      }

      const token = localStorage.getItem("token");
      const headers = {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      };

      try {
        const response = await axios.patch(
          `https://leon-back-hiss.amvera.io/masters/admin/${updatedMaster.id}`,
          formData,
          { headers }
        );
        const updatedMasterData = response.data;
        const index = this.masters.findIndex(
          (master) => master.id === updatedMaster.id
        );
        if (index !== -1) {
          // Обновляем мастера в массиве напрямую
          this.masters.splice(index, 1, updatedMasterData);
        }
        this.closeModal(); // Закрываем модальное окно
        this.showModal("Данные успешно обновлены");
      } catch (error) {
        console.error(error);
        this.showModal("Ошибка при отправке данных");
      }
    },
    showModal(message) {
      this.modalMessage = message;
      this.isModalVisibleInfo = true;
      setTimeout(() => {
        this.isModalVisibleInfo = false;
        this.modalMessage = "";
      }, 2000);
    },
  },
  mounted() {
    this.getMasters();
  },
};
</script>

<style scoped>
form {
  margin: 20px;
}

input[type="file"]::file-selector-button {
  margin-right: 20px;
  border: none;
  background: #f4bc83;
  padding: 10px 20px;
  border-radius: 25px;
  color: #fff;
  cursor: pointer;
  transition: background 0.2s ease-in-out;
}

input[type="file"]::file-selector-button:hover {
  background: #f4bc83;
}

.all-masters-container {
  margin-top: 50px;
}

.master-img {
  max-width: 300px;
}

.master {
  margin: 20px 20px;
}

.master-text {
  margin-top: 10px;
  word-break: break-word;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.edit-master {
  margin-left: 10px;
}

svg {
  max-width: 50px;
  max-height: 50px;
  cursor: pointer;
  fill: #383838;
}

svg:hover {
  fill: #f4bc83;
  transition: fill 0.3s cubic-bezier(0, 0.55, 0.45, 1) 0s;
  /* Плавный переход цвета */
}

@media screen and (max-width: 320px) {
  .master-img {
    max-width: 250px;
  }
}
</style>
