<template>
  <div>
    <h1 style="margin-bottom: 10px">Чёрный список пользователей</h1>
    <table>
      <thead>
        <tr>
          <th>Имя</th>
          <th>Фамилия</th>
          <th>Номер телефона</th>
          <th>Действие</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="phone in phones" :key="phone.id">
          <td>{{ phone.firstName }}</td>
          <td>{{ phone.lastName }}</td>
          <td>{{ phone.phone }}</td>
          <td>
            <button @click="deletePhone(phone.id)">Удалить</button>
          </td>
        </tr>
      </tbody>
    </table>

    <!-- Пагинация -->
    <div class="pagination">
      <button @click="prevPage" :disabled="currentPage === 0">
        Предыдущая
      </button>
      <span>Страница {{ currentPage + 1 }} из {{ totalPages }}</span>
      <button @click="nextPage" :disabled="currentPage >= totalPages - 1">
        Следующая
      </button>
    </div>

    <!-- Форма добавления телефона в черный список -->
    <form @submit.prevent="createPhone">
      <div class="form__group field">
        <input v-model="newPhone.firstName" placeholder="Имя" required class="form__field" />
        <label for="phone" class="form__label">Имя</label>
      </div>
      <div class="form__group field">
        <input v-model="newPhone.lastName" placeholder="Фамилия" required class="form__field" />
        <label for="phone" class="form__label">Фамилия</label>
      </div>
      <div class="form__group field">
        <InputMask v-model="newPhone.phone" placeholder="Номер телефона" required mask="+99999999999"
          class="form__field phone" />
        <label for="phone" class="form__label">Телефон</label>
      </div>
      <ButtonComponent :name="'Добавить'" :type="'submit'">Добавить</ButtonComponent>
    </form>
  </div>
</template>

<script>
import axios from "axios";
import InputMask from "primevue/inputmask";
import ButtonComponent from "@/components/ButtonComponent.vue";

export default {
  components: {
    InputMask,
    ButtonComponent,
  },

  data() {
    return {
      phones: [],
      currentPage: 0, // текущая страница
      pageSize: 10, // количество элементов на странице
      totalRecords: 0, // общее количество записей
      totalPages: 0,
      newPhone: {
        firstName: "",
        lastName: "",
        phone: "",
      }, // данные для нового телефона
    };
  },
  methods: {
    async getPhones() {
      const token = localStorage.getItem("token");
      const headers = {
        Authorization: `Bearer ${token}`,
      };

      try {
        const response = await axios.get(
          `https://leon-back-hiss.amvera.io/rest/admin-ui/blackListPhones?page=${this.currentPage}&size=${this.pageSize}`,
          { headers }
        );
        this.phones = response.data.content; // массив телефонов
        this.totalRecords = response.data.page.totalElements; // общее количество записей
        this.totalPages = response.data.page.totalPages;
      } catch (error) {
        console.error("Ошибка получения списка номеров:", error);
      }
    },

    // Удаление телефона из черного списка
    async deletePhone(id) {
      const token = localStorage.getItem("token");
      const headers = {
        Authorization: `Bearer ${token}`,
      };

      try {
        await axios.delete(
          `https://leon-back-hiss.amvera.io/rest/admin-ui/blackListPhones/${id}`,
          { headers }
        );
        this.getPhones(); // обновить список телефонов
      } catch (error) {
        console.error(`Ошибка при удалении телефона с ID ${id}:`, error);
      }
    },

    // Переход на предыдущую страницу
    prevPage() {
      if (this.currentPage > 0) {
        this.currentPage--;
        this.getPhones();
      }
    },

    // Переход на следующую страницу
    nextPage() {
      if (this.currentPage < this.totalPages - 1) {
        this.currentPage++;
        this.getPhones();
      }
    },

    // Добавление телефона в черный список
    async createPhone() {
      const token = localStorage.getItem("token");
      const headers = {
        Authorization: `Bearer ${token}`,
      };

      try {
        const response = await axios.post(
          "https://leon-back-hiss.amvera.io/rest/admin-ui/blackListPhones",
          this.newPhone,
          { headers }
        );
        this.phones.push(response.data); // добавляем новый телефон в список
        this.newPhone = { firstName: "", lastName: "", phone: "" }; // очистка формы
        this.getPhones(); // обновить список после добавления
      } catch (error) {
        console.error("Ошибка при добавлении нового телефона:", error);
      }
    },
  },
  mounted() {
    this.getPhones(); // загрузить данные при монтировании компонента
  },
};
</script>

<style scoped>
table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 10px;
}

th,
td {
  padding: 8px;
  text-align: left;
}

table,
th,
td {
  border: 1px solid #ddd;
}

.pagination {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

button {
  padding: 5px 10px;
  cursor: pointer;
}

button:disabled {
  cursor: not-allowed;
  background-color: #ddd;
}

@media (max-width: 600px) {
  table {
    font-size: 12px;
  }

  th,
  td {
    padding: 5px;
  }

  .pagination {
    flex-direction: column;
    gap: 10px;
  }
}

.form__group {
  margin-bottom: 15px;
}

.form__field {
  width: 100%;
  padding: 10px;
}

.phone {
  width: 100%;
}
</style>
