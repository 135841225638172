<template>
  <div>
    <div class="main">
      <div class="backgound-img" id="calendar">
        <img :src="background" class="background" alt="" />
      </div>
      <div class="social-links big">
        <a target="_blank" href="https://www.instagram.com/leon.studiokeratin?igsh=MTJiZjI5ZnVzcHZidA=="
          class="social-link-svg">
          <svg width="48.000000" height="48.000000" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink">
            <defs>
              <clipPath id="clip36_856">
                <rect id="Platform=Instagram, Color=Negative" width="48.000000" height="48.000000" fill="white"
                  fill-opacity="0" />
              </clipPath>
            </defs>
            <rect id="Platform=Instagram, Color=Negative" width="48.000000" height="48.000000" fill="#FFFFFF"
              fill-opacity="0" />
            <g clip-path="url(#clip36_856)">
              <path class="social-link-svg-fill" id="Vector"
                d="M24 4.32C30.41 4.32 31.17 4.35 33.69 4.46C36.03 4.56 37.3 4.95 38.14 5.28C39.26 5.71 40.06 6.24 40.9 7.07C41.74 7.92 42.26 8.71 42.69 9.83C43.02 10.67 43.41 11.95 43.51 14.28C43.63 16.81 43.65 17.57 43.65 23.98C43.65 30.39 43.63 31.15 43.51 33.67C43.41 36.01 43.02 37.28 42.69 38.12C42.26 39.24 41.73 40.05 40.9 40.88C40.05 41.72 39.26 42.24 38.14 42.67C37.3 43 36.02 43.39 33.69 43.5C31.16 43.61 30.4 43.64 24 43.64C17.58 43.64 16.82 43.61 14.3 43.5C11.96 43.39 10.69 43 9.85 42.67C8.73 42.24 7.93 41.71 7.09 40.88C6.25 40.04 5.73 39.24 5.3 38.12C4.97 37.28 4.58 36 4.48 33.67C4.36 31.14 4.34 30.38 4.34 23.98C4.34 17.56 4.36 16.8 4.48 14.28C4.58 11.94 4.97 10.67 5.3 9.83C5.73 8.71 6.26 7.91 7.09 7.07C7.94 6.23 8.73 5.71 9.85 5.28C10.69 4.95 11.97 4.56 14.3 4.46C16.82 4.35 17.58 4.32 24 4.32ZM24 0C17.48 0 16.66 0.02 14.1 0.14C11.55 0.25 9.8 0.66 8.28 1.25C6.7 1.87 5.36 2.69 4.03 4.03C2.69 5.36 1.87 6.7 1.25 8.27C0.66 9.8 0.25 11.55 0.14 14.1C0.02 16.66 0 17.48 0 24C0 30.51 0.02 31.33 0.14 33.89C0.25 36.44 0.66 38.19 1.25 39.71C1.87 41.29 2.69 42.63 4.03 43.96C5.36 45.3 6.7 46.12 8.27 46.73C9.8 47.32 11.55 47.73 14.1 47.85C16.65 47.96 17.47 47.99 23.99 47.99C30.5 47.99 31.32 47.96 33.88 47.85C36.43 47.73 38.18 47.32 39.7 46.73C41.27 46.12 42.61 45.3 43.95 43.96C45.28 42.63 46.1 41.29 46.71 39.72C47.3 38.19 47.71 36.45 47.83 33.9C47.94 31.34 47.97 30.52 47.97 24C47.97 17.49 47.94 16.67 47.83 14.11C47.71 11.56 47.3 9.81 46.71 8.29C46.12 6.7 45.3 5.36 43.96 4.03C42.63 2.7 41.29 1.87 39.72 1.26C38.19 0.67 36.45 0.26 33.9 0.14C31.33 0.02 30.51 0 24 0Z"
                fill="#383838" fill-opacity="1.000000" fill-rule="nonzero" />
              <path class="social-link-svg-fill" id="Vector"
                d="M24 11.67C17.19 11.67 11.67 17.19 11.67 24C11.67 30.8 17.19 36.32 24 36.32C30.8 36.32 36.32 30.8 36.32 24C36.32 17.19 30.8 11.67 24 11.67ZM24 31.99C19.58 31.99 16 28.41 16 24C16 19.58 19.58 16 24 16C28.41 16 31.99 19.58 31.99 24C31.99 28.41 28.41 31.99 24 31.99Z"
                fill="#383838" fill-opacity="1.000000" fill-rule="nonzero" />
              <path class="social-link-svg-fill" id="Vector"
                d="M39.69 11.18C39.69 12.77 38.4 14.06 36.81 14.06C35.22 14.06 33.93 12.76 33.93 11.18C33.93 9.59 35.23 8.3 36.81 8.3C38.4 8.3 39.69 9.59 39.69 11.18Z"
                fill="#383838" fill-opacity="1.000000" fill-rule="nonzero" />
            </g>
          </svg>
        </a>
        <a target="_blank" href="https://vk.com/lvova.hair" class="social-link-svg">
          <svg width="48.000000" height="48.000000" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink">
            <desc>Created with Pixso.</desc>
            <defs>
              <clipPath id="clip36_854">
                <rect id="Platform=VK, Color=Negative" width="48.000000" height="48.000000" fill="white"
                  fill-opacity="0" />
              </clipPath>
            </defs>
            <rect id="Platform=VK, Color=Negative" width="48.000000" height="48.000000" fill="#FFFFFF"
              fill-opacity="0" />
            <g clip-path="url(#clip36_854)">
              <path class="social-link-svg-fill" id="Vector"
                d="M3.37 3.37C0 6.74 0 12.17 0 23.04L0 24.96C0 35.82 0 41.25 3.37 44.62C6.74 48 12.17 48 23.04 48L24.96 48C35.82 48 41.25 48 44.62 44.62C48 41.25 48 35.82 48 24.96L48 23.04C48 12.17 48 6.74 44.62 3.37C41.25 0 35.82 0 24.96 0L23.04 0C12.17 0 6.74 0 3.37 3.37ZM8.1 14.6C8.36 27.08 14.6 34.58 25.54 34.58L26.16 34.58L26.16 27.44C30.18 27.84 33.22 30.78 34.43 34.58L40.12 34.58C38.56 28.9 34.45 25.76 31.89 24.56C34.45 23.08 38.05 19.48 38.91 14.6L33.75 14.6C32.63 18.56 29.32 22.16 26.16 22.5L26.16 14.6L21 14.6L21 28.44C17.79 27.64 13.76 23.76 13.58 14.6L8.1 14.6Z"
                fill="#383838" fill-opacity="1.000000" fill-rule="evenodd" />
            </g>
          </svg>
        </a>
        <a target="_blank" href="https://t.me/lvova_hair" class="social-link-svg">
          <svg width="48.000000" height="48.000000" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink">
            <desc>Created with Pixso.</desc>
            <defs>
              <clipPath id="clip36_855">
                <rect id="Platform=Telegram, Color=Negative" width="48.000000" height="48.000000" fill="white"
                  fill-opacity="0" />
              </clipPath>
            </defs>
            <rect id="Platform=Telegram, Color=Negative" width="48.000000" height="48.000000" fill="#FFFFFF"
              fill-opacity="0" />
            <g clip-path="url(#clip36_855)">
              <path class="social-link-svg-fill" id="Vector"
                d="M48 24C48 37.25 37.25 48 24 48C10.74 48 0 37.25 0 24C0 10.74 10.74 0 24 0C37.25 0 48 10.74 48 24ZM24.86 17.71C22.52 18.68 17.86 20.69 10.86 23.74C9.72 24.19 9.13 24.64 9.07 25.07C8.98 25.8 9.9 26.09 11.14 26.48C11.31 26.53 11.49 26.59 11.67 26.64C12.89 27.04 14.54 27.51 15.4 27.53C16.17 27.54 17.04 27.22 18 26.57C24.54 22.15 27.91 19.92 28.12 19.87C28.27 19.84 28.48 19.8 28.62 19.92C28.76 20.05 28.75 20.28 28.73 20.35C28.64 20.73 25.05 24.07 23.19 25.8C22.61 26.34 22.2 26.72 22.12 26.81C21.93 27 21.74 27.19 21.55 27.36C20.42 28.46 19.56 29.28 21.6 30.63C22.58 31.27 23.37 31.81 24.15 32.34C25 32.92 25.86 33.5 26.96 34.23C27.24 34.41 27.51 34.6 27.77 34.79C28.76 35.5 29.66 36.13 30.76 36.03C31.4 35.97 32.06 35.37 32.4 33.57C33.2 29.32 34.76 20.11 35.12 16.32C35.15 15.98 35.11 15.56 35.08 15.37C35.05 15.19 34.98 14.92 34.74 14.72C34.45 14.49 34.01 14.44 33.81 14.44C32.91 14.46 31.52 14.94 24.86 17.71Z"
                fill="#383838" fill-opacity="1.000000" fill-rule="evenodd" />
            </g>
          </svg>
        </a>
      </div>
      <!-- Заголовок -->
      <div class="title-container-and-calendar">
        <div class="title-container">
          <div class="subtitle">Студия реконструкции волос</div>
          <div class="title-and-arrow">
            <div class="title">Леон</div>
            <div class="text-and-arrow">
              <div class="text">записаться</div>
              <div class="arrow">
                <svg width="128.121323" height="45.181969" viewBox="0 0 128.121 45.182" fill="none"
                  xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                  <desc>Created with Pixso.</desc>
                  <defs />
                  <path id="Стрелка 1"
                    d="M103.93 3.18L103.95 3.19L122.37 21.62L0 21.62L0 24.62L122.37 24.62L103.93 43.06L106.06 45.18L128.12 23.12L106.07 1.07L106.06 1.06L105 0L102.87 2.12L103.93 3.18Z"
                    fill="#f4bc83" fill-opacity="1.000000" fill-rule="evenodd" />
                </svg>
              </div>
            </div>
          </div>
        </div>
        <!-- Календарь -->
        <div class="calendar">
          <div class="month">
            <div class="arrow-left" :class="{ disabled: !canNavigatePreviousMonth }" @click="previousMonth">
              <svg width="8.418304" height="14.008179" viewBox="0 0 8.4183 14.0082" fill="none"
                xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                <desc>Created with Pixso.</desc>
                <defs />
                <path id="Vector" d="M7.41 13L1.41 7L7.41 1" stroke="#FFFFFF" stroke-opacity="1.000000"
                  stroke-width="2.000000" stroke-linecap="round" />
              </svg>
            </div>
            <div class="month-name">
              {{ monthNames[currentMonth] }} {{ currentYear }}
            </div>
            <div class="arrow-right" :class="{ disabled: !canNavigateNextMonth }" @click="nextMonth">
              <svg width="24.000000" height="24.000000" viewBox="0 0 24 24" fill="none"
                xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                <desc>Created with Pixso.</desc>
                <defs>
                  <clipPath id="clip62_441">
                    <rect id="chevron-right" width="24.000000" height="24.000000" fill="white" fill-opacity="0" />
                  </clipPath>
                </defs>
                <rect id="chevron-right" width="24.000000" height="24.000000" fill="#FFFFFF" fill-opacity="0" />
                <g clip-path="url(#clip62_441)">
                  <path id="Vector" d="M9 18L15 12L9 6" stroke="#FFFFFF" stroke-opacity="1.000000"
                    stroke-width="2.000000" stroke-linejoin="round" stroke-linecap="round" />
                </g>
              </svg>
            </div>
          </div>
          <div class="calendar-days">
            <div class="weekday">
              <div class="weekday-name" v-for="day in weekdayNames" :key="day">
                {{ day }}
              </div>
            </div>
            <div class="days">
              <div v-for="day in calendarDays" :key="day.id">
                <div class="day" :class="{
                  disabled: !day.clickable,
                  today: day.isToday,
                  selected: day.isSelected,
                }" @click="handleClick(day)">
                  {{ day.formattedDate }}
                </div>
              </div>
            </div>
          </div>
          <div class="all-masters-container">
            <div class="all-masters">
              <div class="masters" v-for="master in filteredMasters" :key="master.id">
                <div class="master-img-container">
                  <img class="master-img" :src="'data:image/png;base64,' + master.master?.image"
                    :alt="master.firstName" />
                </div>
                <div class="master-and-times">
                  <div class="master">{{ master.master.firstName }}</div>
                  <div class="all-times">
                    <div class="times" v-for="time in master.timeSlots.sort(
                      (a, b) =>
                        new Date(`2000-01-01T${a.time}`) -
                        new Date(`2000-01-01T${b.time}`)
                    )" :key="time.id">
                      <div v-if="isValidTime(time)" class="time" @click="selectTime(time, master.master)" :class="{
                        disabled: !time.working,
                        selected: time.isSelected,
                      }">
                        {{ formatTime(time.time) }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div v-if="isLoading" class="loaders">
              <div id="wifi-loader">
                <svg class="circle-outer" viewBox="0 0 86 86">
                  <circle class="back" cx="43" cy="43" r="40"></circle>
                  <circle class="front" cx="43" cy="43" r="40"></circle>
                  <circle class="new" cx="43" cy="43" r="40"></circle>
                </svg>
                <svg class="circle-middle" viewBox="0 0 60 60">
                  <circle class="back" cx="30" cy="30" r="27"></circle>
                  <circle class="front" cx="30" cy="30" r="27"></circle>
                </svg>
                <svg class="circle-inner" viewBox="0 0 34 34">
                  <circle class="back" cx="17" cy="17" r="14"></circle>
                  <circle class="front" cx="17" cy="17" r="14"></circle>
                </svg>
                <div class="text" data-text="Поиск"></div>
              </div>
            </div>
          </div>
          <div class="button-container">
            <button-component :name="'Далее'" :type="button" :disabled="disableButton"
              @click="showModal = true"></button-component>
            <Modal v-if="showModal" :show="showModal" :master="selectedMaster" :date="selectedDay" :time="selectedTime"
              @close="showModal = false" />
          </div>
        </div>
      </div>
    </div>
    <!-- Блок "О нас" -->
    <!-- Линии -->
    <!-- <div class="lines-background-1"></div> -->

    <div class="block-site" id="aboutUs">
      <Line :top="'210'" :transform="'-33deg'" />
      <div class="about-us-container">
        <div class="title-site">О нас</div>
        <div class="about-us-content">
          <div class="about-us-img-container">
            <img :src="aboutUsImg1" alt="about us img" class="about-us-img" />
            <img :src="aboutUsImg2" alt="about us img" class="about-us-img" />
          </div>
          <div class="about-us-text">
            Добро пожаловать в студию реконструкции волос Леон — место, где ваши
            волосы обретают новую жизнь и сияние! Мы гордимся тем, что создаем
            уникальные образы и восстанавливаем здоровье волос с использованием
            передовых технологий и высококачественных продуктов.
            <br />
            <br />
            Наши специалисты постоянно совершенствуют свои навыки и следят за
            последними тенденциями в мире красоты, чтобы предложить вам только
            лучшее. В студии Леон мы создаем атмосферу уюта и доверия, где
            каждый визит становится приятным и запоминающимся.
          </div>
        </div>
      </div>
    </div>
    <!-- Наши работы -->
    <div class="block-site" id="works">
      <Line :top="'110'" :transform="'30deg'" />
      <div class="works-container">
        <div class="title-site">Наши работы</div>
        <Slider />
      </div>
    </div>
    <!-- Мастера -->
    <div class="block-site" id="masters">
      <Line :top="'370'" :transform="'13deg'" />
      <div class="masters-container">
        <div class="title-site">Мастера</div>
        <Masters />
      </div>
    </div>
    <!-- Отзывы -->
    <div class="block-site" id="reviews">
      <Line class="reviews-line" :top="'70'" :transform="'-15deg'" />
      <div class="reviews-container">
        <div class="title-site">Отзывы</div>
        <div class="reviews-all">
          <Reviews />
        </div>
      </div>
    </div>
    <!-- Домашний уход -->
    <div class="block-site" id="homeCare">
      <Line :top="'70'" :transform="'-30deg'" />
      <div class="home-care-container">
        <div class="title-site">Домашний уход</div>
        <HomeCare />
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import ButtonComponent from "@/components/ButtonComponent.vue";
import Line from "@/components/Line.vue";
import Slider from "@/components/Slider.vue";
import Masters from "@/components/Masters.vue";
import Reviews from "@/components/Reviews.vue";
import HomeCare from "@/components/HomeCare.vue";
import Modal from "@/components/Modal.vue";

export default {
  components: {
    ButtonComponent,
    Line,
    Slider,
    Masters,
    Reviews,
    HomeCare,
    Modal,
  },
  data() {
    return {
      background: require("../assets/background.jpeg"),
      lines: require("../assets/lines.png"),
      aboutUsImg1: require("../assets/about_us_1.jpg"),
      aboutUsImg2: require("../assets/about_us_2.jpg"),
      days: [],
      currentYear: new Date().getFullYear(),
      currentMonth: new Date().getMonth(),
      selectedDay: null,
      selectedTime: null,
      selectedMaster: null,
      monthNames: [
        "Январь",
        "Февраль",
        "Март",
        "Апрель",
        "Май",
        "Июнь",
        "Июль",
        "Август",
        "Сентябрь",
        "Октябрь",
        "Ноябрь",
        "Декабрь",
      ],
      weekdayNames: ["Пн", "Вт", "Ср", "Чт", "Пт", "Сб", "Вс"],
      hasNextMonthData: true,
      masters: [],
      showModal: false,
      disableButton: true,
      isLoading: false,
    };
  },
  methods: {
    async getSchedules(year, month) {
      this.isLoading = true;
      try {
        const response = await axios.get(
          `https://leon-back-hiss.amvera.io/schedule/month?year=${year}&month=${month + 1
          }`
        );
        this.days = response.data;
        console.log(this.days);
        await this.checkNextMonth(year, month);
      } catch (error) {
        console.error(error);
        this.days = [];
        await this.checkNextMonth(year, month);
        // Обработка ошибки
      } finally {
        this.isLoading = false;
      }
    },
    async checkNextMonth(year, month) {
      try {
        const response = await axios.get(
          `https://leon-back-hiss.amvera.io/schedule/month?year=${year}&month=${month + 2 > 12 ? 1 : month + 2
          }`
        );
        this.hasNextMonthData = response.data.length > 0;
      } catch (error) {
        console.error(error);
        this.hasNextMonthData = false;
        // Обработка ошибки
      }
    },
    previousMonth() {
      if (this.canNavigatePreviousMonth) {
        this.currentMonth--;
        if (this.currentMonth < 0) {
          this.currentMonth = 11;
          this.currentYear--;
        }
        this.getSchedules(this.currentYear, this.currentMonth);
      }
    },
    nextMonth() {
      if (this.canNavigateNextMonth) {
        this.currentMonth++;
        if (this.currentMonth > 11) {
          this.currentMonth = 0;
          this.currentYear++;
        }
        this.getSchedules(this.currentYear, this.currentMonth);
      }
    },
    async handleClick(day) {
      if (day.clickable) {
        this.isLoading = true; // показываем анимацию загрузки
        this.selectedDay = day.date;
        const date = new Date(day.date);
        const year = date.getFullYear();
        const month = date.getMonth() + 1;
        const dayOfMonth = date.getDate();

        try {
          const response = await axios.get(
            `https://leon-back-hiss.amvera.io/schedule/day?year=${year}&month=${month}&day=${dayOfMonth}`
          );
          console.log(response.data);
          this.masters = response.data;
        } catch (error) {
          console.error(error);
          // Обработка ошибки
        } finally {
          this.isLoading = false; // скрываем анимацию загрузки
        }
      }
    },
    formatTime(time) {
      return time.slice(0, 5);
    },
    selectTime(time, master) {
      if (time.working) {
        this.selectedTime = time.time;
        this.selectedMaster = master;
        console.log(
          `Выбранное время: ${this.selectedTime}, мастер: ${master.id} ${master.firstName} ${master.lastName}`
        );

        if (this.selectedTime) this.disableButton = false;

        this.masters.forEach((master) => {
          master.timeSlots.forEach((slot) => {
            slot.isSelected = false;
          });
        });

        time.isSelected = true;
      }
    },
    isValidTime(time) {
      const currentDate = new Date();
      const selectedDate = new Date(this.selectedDay);
      const [hours, minutes] = time.time.split(":").map(Number);

      if (selectedDate.toDateString() === currentDate.toDateString()) {
        const currentHours = currentDate.getHours();
        const currentMinutes = currentDate.getMinutes();

        if (currentHours < 12 && hours < 12) {
          return false;
        }

        if (
          hours < currentHours ||
          (hours === currentHours && minutes <= currentMinutes)
        ) {
          return false;
        }
      }

      return true;
    },
  },
  computed: {
    calendarDays() {
      const today = new Date();
      const firstDay = new Date(this.currentYear, this.currentMonth).getDay();
      const daysInMonth = new Date(
        this.currentYear,
        this.currentMonth + 1,
        0
      ).getDate();

      const days = [];

      // Корректировка дня недели для начала с понедельника
      const adjustedFirstDay = firstDay === 0 ? 7 : firstDay;

      // Добавляем пустые ячейки до начала месяца
      for (let i = 1; i < adjustedFirstDay; i++) {
        days.push({
          clickable: false,
        });
      }

      for (let i = 1; i <= daysInMonth; i++) {
        const date = new Date(this.currentYear, this.currentMonth, i);
        const formattedDate = `${i}`;
        const clickable = date >= today.setHours(0, 0, 0, 0);
        const isToday = date.toDateString() === today.toDateString();
        const isSelected =
          this.selectedDay &&
          date.toDateString() === new Date(this.selectedDay).toDateString();

        days.push({
          id: `${this.currentYear}-${this.currentMonth + 1}-${i}`,
          date,
          formattedDate,
          clickable,
          isToday,
          isSelected,
        });
      }

      return days;
    },
    canNavigatePreviousMonth() {
      const today = new Date();
      return (
        this.currentYear > today.getFullYear() ||
        (this.currentYear === today.getFullYear() &&
          this.currentMonth > today.getMonth())
      );
    },
    canNavigateNextMonth() {
      return this.hasNextMonthData;
    },
    filteredMasters() {
      return this.masters.filter((master) =>
        master.timeSlots.some((slot) => slot.working)
      );
    },
  },
  async mounted() {
    await this.getSchedules(this.currentYear, this.currentMonth);
  },
};
</script>

<style scoped>
.main {
  padding-bottom: 200px;
}

.backgound-img {
  position: absolute;
  top: 0;
  z-index: -1;
  height: 900px;
  overflow: hidden;
}

.background {
  width: 100%;
  /* height: 700px; */
  overflow: hidden;
}

/* Ссылки на социальные сети */
.social-links {
  margin-left: 71px;
  margin-top: 94px;
  display: grid;
  width: 52px;
}

.small {
  display: none;
}

.social-link-svg {
  margin-bottom: 30px;
  max-width: 51px;
}

.social-link-svg:hover .social-link-svg-fill {
  fill: #f4bc83;
  transition: fill 0.3s cubic-bezier(0, 0.55, 0.45, 1) 0s;
  /* Плавный переход цвета */
}

/* Заголовк */
.title-container {
  margin-top: 27px;
  margin-left: 164px;
  height: 170px;
}

.subtitle {
  font-size: 48px;
  font-weight: 400;
  line-height: 22px;
  margin-left: 110px;
  margin-bottom: 25px;
}

.title {
  font-size: 200px;
  font-weight: 400;
}

.text-and-arrow {
  color: #f4bc83;
  margin-left: 120px;
  font-size: 64px;
  margin-top: 20px;
}

.title-and-arrow {
  display: flex;
}

.arrow {
  margin-left: 220px;
  animation: moveArrow 2s infinite;
}

@keyframes moveArrow {

  0%,
  100% {
    transform: translateX(0);
  }

  50% {
    transform: translateX(20px);
    /* Измените значение, чтобы настроить расстояние движения */
  }
}

.title-container-and-calendar {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

/* Стили для календаря */

@font-face {
  font-family: "Montserrat Bold";
  src: url("../../src/assets/fonts/Montserrat/static/Montserrat-Black.ttf");
}

@font-face {
  font-family: "Montserrat Medium";
  src: url("../../src/assets/fonts/Montserrat/static/Montserrat-Medium.ttf");
}

@media screen and (max-width: 1280px) {
  .subtitle {
    font-size: 40px;
  }

  .text-and-arrow {
    display: none;
  }

  .title-container-and-calendar {
    bottom: 40px;
  }
}

@media screen and (max-width: 950px) {
  .title-container-and-calendar {
    bottom: -40px;
  }

  .title {
    font-size: 140px !important;
  }

  .about-us-text {
    font-size: 16px !important;
  }

  .title-site {
    margin-left: 0;
  }

  .li-list[data-v-5c833af0] {
    margin-right: 20px;
  }
}

@media screen and (max-width: 712px) {
  .title-site {
    margin-left: 0;
  }

  .title-container-and-calendar {
    bottom: 0;
  }
}

/* Стили для календаря */

@media screen and (max-width: 480px) {
  .backgound-img {
    display: none;
  }

  .big {
    display: none;
  }

  .small {
    display: flex;
  }

  .calendar {
    width: 90%;
  }

  .calendar-days {
    gap: 5px;
    padding: 0 20px;
  }

  .day {
    font-size: 16px;
    padding: 5px;
  }
}

.calendar {
  width: 427px;
  height: 725px;
  background-color: #1a1a1a;
  margin-left: auto;
  position: absolute;
  right: 223px;
  bottom: -160px;
  border-radius: 20px;
  display: grid;
}

.month {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.month-name {
  font-size: 20px;
  font-weight: bold;
  padding: 16px 0 16px 0;
}

.arrow-left,
.arrow-right {
  cursor: pointer;
}

.arrow-left {
  margin-left: 32px;
}

.arrow-right {
  margin-right: 32px;
}

.calendar-days {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  /* 7 колонок для 7 дней в неделе */
  gap: 10px;
  /* Промежуток между ячейками */
  padding: 0 32px;
  /* Отступы для календаря */
}

.weekday {
  display: contents;
  /* Используем display: contents для правильного отображения в grid */
}

.weekday-name {
  font-family: "Montserrat Bold";
  font-weight: bold;
  font-size: 12px;
  text-align: center;
}

.days {
  display: contents;
  /* Используем display: contents для правильного отображения в grid */
  -webkit-touch-callout: none;
}

.day {
  font-family: "Montserrat Medium";
  font-size: 18px;
  text-align: center;
  padding: 10px;
  border-radius: 50%;
  /* Скругление углов */
  cursor: pointer;
  border: 2px solid #1a1a1a;
}

.day:hover {
  background-color: #e0e0e0;
  /* Изменение цвета фона при наведении */
  color: #1a1a1a;
}

.not-working {
  color: #383838;
}

.day.not-working {
  color: #383838;
}

.day.disabled {
  color: #383838;
}

.day.today {
  color: #f4bc83;
  display: grid;
  justify-items: center;
}

.day.today::after {
  content: "";
  display: block;
  width: 5px;
  height: 5px;
  background-color: #f4bc83;
}

.day.selected {
  border: 2px solid #f4bc83;
  border-radius: 10px;
}

.arrow-right.disabled,
.arrow-left.disabled {
  opacity: 0.5;
}

.disabled {
  opacity: 0.5;
}

/* Отображение мастеров в календаре */
.master-img {
  max-width: 75px;
  max-height: 75px;
}

.masters {
  display: flex;
  padding-left: 20px;
  padding-top: 31px;
}

.master-img-container {
  padding-right: 15px;
}

.master-and-times {
  display: grid;
}

.master {
  font-family: "Montserrat Medium";
  padding-top: 10px;
  font-size: 16px;
}

.all-times {
  display: flex;
  flex-wrap: wrap;
  font-family: "Montserrat Medium";
}

.times {
  margin-right: 10px;
}

.time {
  font-size: 14px;
  padding: 2px 5px;
  background-color: #383838;
  cursor: pointer;
  border: 2px solid #1a1a1a;
  border-radius: 10px;
}

.time.selected {
  border: 2px solid #f4bc83;
}

.all-masters-container {
  height: 220px;
  overflow: hidden;
  /* Скрыть скроллбар */
}

.all-masters {
  height: 220px;
  overflow-y: scroll;
  padding-right: 15px;
  /* Добавляем пространство, чтобы контент не обрезался */
  margin-right: -15px;
  /* Выравниваем с контейнером */
}

/* Скрытие скроллбара для WebKit-браузеров */
.all-masters::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.button-container {
  text-align: center;
  /* margin-top: 10px; */
}

/* Стили для линий */
/* Контейнер для фона с линиями */
.lines-background-1 {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: url("../assets/lines.png") no-repeat;
  /* Замените на путь к вашему изображению */
  background-size: cover;
  /* Обеспечивает покрытие всего элемента */
  z-index: -2;
  /* Размещает под основным контентом */
}

/* Блок "О нас" */

.about-us-content {
  display: flex;
  align-items: center;
  /* margin-top: 50px; */
}

.about-us-img-container {
  display: flex;
}

.about-us-img:nth-child(2) {
  margin-left: 20px;
}

.about-us-img {
  border-radius: 20px;
  max-width: 400px;
}

.about-us-text {
  font-size: 24px;
  max-width: 750px;
  max-height: 462px;
  padding-left: 40px;
}

/* Наши работы */
.works {
  position: relative;
}

@media screen and (max-width: 1540px) {
  .calendar {
    right: 30px;
  }

  .title {
    font-size: 170px;
  }

  .text-and-arrow {
    margin-left: 30px;
  }

  .about-us-img {
    max-width: 400px;
  }
}

@media screen and (max-width: 1024px) {
  .background {
    width: auto !important;
    height: 542px;
  }

  .backgound-img {
    width: 1024px;
  }

  .title-container {
    position: relative;
    bottom: 380px;
  }

  .text-and-arrow {
    display: none;
  }

  .calendar {
    border: 2px #f4bc83 solid;
  }

  /* .title, .subtitle {
    display: none;
  } */

  .about-us-img {
    max-width: 300px;
  }

  .about-us-text {
    font-size: 18px;
  }

  .reviews-line {
    top: 170px !important;
  }
}

@media screen and (max-width: 768px) {
  .background {}

  .title-container {
    display: none;
  }

  .calendar {
    transform: translateX(50%);
    right: auto;
    bottom: -300px;
  }

  .about-us-img-container {
    display: flex;
    flex-direction: column;
  }

  .about-us-img {
    margin-left: 0 !important;
    margin-bottom: 20px;
  }

  .backgound-img {
    width: 768px;
  }

  .block-site {
    margin-top: 150px;
  }
}

@media screen and (max-width: 480px) {
  .backgound-img {
    display: none;
    /* height: 180px; */
  }

  .big {
    display: none;
  }

  .small {
    display: flex;
  }

  .calendar {
    /* transform: translateX(6%); */
    /* right: 30px;
    bottom: -500px;
    width: 350px;
    margin: 0 auto; */
    position: relative;
    right: auto;
    bottom: -300px;
    margin: 0 10px;
    transform: translateX(0);
  }

  .calendar-days {
    gap: 5px;
    padding: 0 20px;
  }

  .day {
    font-size: 16px;
    padding: 5px;
  }

  .about-us-img {
    max-width: 180px;
  }

  .about-us-img-container {
    flex-direction: row;
    justify-content: space-around;
  }

  .about-us-content {
    display: grid;
  }

  .about-us-text {
    padding-left: 0;
    font-size: 16px;
    margin-left: 10px;
  }

  .about-us-container {
    margin-top: 500px;
  }

  .title-container-and-calendar {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100px;
  }

  .social-links {
    width: auto;
    text-align: center;
    display: flex;
    justify-content: space-evenly;
    margin: 0;
  }
}

/* Загрузка */

.loaders {
  /* left: 150px; */
  /* width: 200px; */
  /* height: 200px; */
  /* position: relative; */
  /* top: 100px; */
  /* right: 20px; */
  display: grid;
  justify-content: center;
  align-items: center;
  position: relative;
  bottom: 170px;
  margin: 0;
  padding: 0;
}

#wifi-loader {
  --background: #62abff;
  --front-color: #f4bc83;
  --back-color: #c3c8de;
  --text-color: #fff;
  width: 64px;
  height: 64px;
  border-radius: 50px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

#wifi-loader svg {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
}

#wifi-loader svg circle {
  position: absolute;
  fill: none;
  stroke-width: 6px;
  stroke-linecap: round;
  stroke-linejoin: round;
  transform: rotate(-100deg);
  transform-origin: center;
}

#wifi-loader svg circle.back {
  stroke: var(--back-color);
}

#wifi-loader svg circle.front {
  stroke: var(--front-color);
}

#wifi-loader svg.circle-outer {
  height: 86px;
  width: 86px;
}

#wifi-loader svg.circle-outer circle {
  stroke-dasharray: 62.75 188.25;
}

#wifi-loader svg.circle-outer circle.back {
  animation: circle-outer135 1.8s ease infinite 0.3s;
}

#wifi-loader svg.circle-outer circle.front {
  animation: circle-outer135 1.8s ease infinite 0.15s;
}

#wifi-loader svg.circle-middle {
  height: 60px;
  width: 60px;
}

#wifi-loader svg.circle-middle circle {
  stroke-dasharray: 42.5 127.5;
}

#wifi-loader svg.circle-middle circle.back {
  animation: circle-middle6123 1.8s ease infinite 0.25s;
}

#wifi-loader svg.circle-middle circle.front {
  animation: circle-middle6123 1.8s ease infinite 0.1s;
}

#wifi-loader svg.circle-inner {
  height: 34px;
  width: 34px;
}

#wifi-loader svg.circle-inner circle {
  stroke-dasharray: 22 66;
}

#wifi-loader svg.circle-inner circle.back {
  animation: circle-inner162 1.8s ease infinite 0.2s;
}

#wifi-loader svg.circle-inner circle.front {
  animation: circle-inner162 1.8s ease infinite 0.05s;
}

#wifi-loader .text {
  position: absolute;
  bottom: -40px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: lowercase;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 0.2px;
}

#wifi-loader .text::before,
#wifi-loader .text::after {
  content: attr(data-text);
}

#wifi-loader .text::before {
  color: var(--text-color);
}

#wifi-loader .text::after {
  color: var(--front-color);
  animation: text-animation76 3.6s ease infinite;
  position: absolute;
  left: 0;
}

@keyframes circle-outer135 {
  0% {
    stroke-dashoffset: 25;
  }

  25% {
    stroke-dashoffset: 0;
  }

  65% {
    stroke-dashoffset: 301;
  }

  80% {
    stroke-dashoffset: 276;
  }

  100% {
    stroke-dashoffset: 276;
  }
}

@keyframes circle-middle6123 {
  0% {
    stroke-dashoffset: 17;
  }

  25% {
    stroke-dashoffset: 0;
  }

  65% {
    stroke-dashoffset: 204;
  }

  80% {
    stroke-dashoffset: 187;
  }

  100% {
    stroke-dashoffset: 187;
  }
}

@keyframes circle-inner162 {
  0% {
    stroke-dashoffset: 9;
  }

  25% {
    stroke-dashoffset: 0;
  }

  65% {
    stroke-dashoffset: 106;
  }

  80% {
    stroke-dashoffset: 97;
  }

  100% {
    stroke-dashoffset: 97;
  }
}

@keyframes text-animation76 {
  0% {
    clip-path: inset(0 100% 0 0);
  }

  50% {
    clip-path: inset(0);
  }

  100% {
    clip-path: inset(0 0 0 100%);
  }
}

/*  */
@media screen and (max-width: 320px) {
  .about-us-img {
    max-width: 150px;
  }
}
</style>
