<template>
  <div>
    <div class="card">
      <div class="filter-title">Создание домашнего ухода</div>
      <form @submit.prevent="submitForm">
        <div class="form__group field">
          <input type="file" @change="onFileSelect($event, 1)" required />
        </div>
        <div class="form__group field">
          <input type="file" @change="onFileSelect($event, 2)" required />
        </div>
        <Editor v-model="dataProperty" editorStyle="height: 320px" />
        <HintComponent>
          <p>Название домашнего ухода должно быть Subheading</p>
          <p>Цены должны быть в виде списка и цена указана жирным</p>
        </HintComponent>
        <ButtonComponent :name="'Сохранить'" :type="submit" style="margin-top: 20px" />
      </form>
    </div>
    <ModalInfo v-if="isModalVisible" :message="modalMessage" @close="isModalVisible = false" />
    <!-- <div class="all-home-care">
      <div class="home-care" v-for="homeCare in homeCares" :key="homeCare.id">
        <div class="home-care-image-all">
          
        </div>
        <div class="home-care-text" v-html="homeCare.text"></div>
      </div>
    </div> -->
  </div>
</template>

<script>
import axios from "axios";
import Editor from "primevue/editor";
import ButtonComponent from "@/components/ButtonComponent.vue";
import ModalInfo from "@/components/ModalInfo.vue";
import Tooltip from "primevue/tooltip";
import HintComponent from "./HintComponent.vue";

export default {
  directives: {
    tooltip: Tooltip,
  },
  components: {
    ButtonComponent,
    Editor,
    ModalInfo,
    HintComponent,
  },
  data() {
    return {
      dataProperty: "",
      image1: null,
      image2: null,
      isModalVisible: false,
      modalMessage: "",
      homeCares: [],
    };
  },
  methods: {
    onFileSelect(event, fileNumber) {
      const file = event.target.files[0];
      if (fileNumber === 1) {
        this.file1 = file;
      } else if (fileNumber === 2) {
        this.file2 = file;
      }
    },
    submitForm() {
      const formData = new FormData();
      formData.append("image1", this.file1);
      formData.append("image2", this.file2);
      formData.append("text", this.dataProperty);

      const token = localStorage.getItem("token");
      const headers = {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      };

      axios
        .post("https://leon-back-hiss.amvera.io/home-care", formData, {
          headers,
        })
        .then((response) => {
          this.showModal("Данные успешно отправлены");
        })
        .catch((error) => {
          console.error("Ошибка при отправке данных", error);
          this.showModal("Ошибка при отправке данных");
        });
    },
    showModal(message) {
      this.modalMessage = message;
      this.isModalVisible = true;
      setTimeout(() => {
        this.isModalVisible = false;
        this.modalMessage = "";
      }, 2000);
    },
    getHomeCare() {
      const token = localStorage.getItem("token");
      const headers = {
        Authorization: `Bearer ${token}`,
      };
      axios
        .get("https://leon-back-hiss.amvera.io/home-care", { headers })
        .then((response) => {
          this.homeCares = response.data;
        })
        .catch((error) => {
          console.error("Ошибка при получении данных", error);
        });
    },
  },
  mounted() {
    this.getHomeCare();
  },
};
</script>

<style scoped>
.card {
  background-color: rgba(56, 56, 56, 0.6);
  backdrop-filter: blur(30px);
  border: 1px solid #ddd;
  padding: 20px;
  margin-bottom: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.filter-title {
  font-size: 22px;
  color: #f4bc83;
  margin-bottom: 20px;
  font-weight: bold;
}

input[type="file"]::file-selector-button {
  margin-right: 20px;
  border: none;
  background: #f4bc83;
  padding: 10px 20px;
  border-radius: 25px;
  color: #fff;
  cursor: pointer;
  transition: background 0.2s ease-in-out;
}

input[type="file"]::file-selector-button:hover {
  background: #f4bc83;
}

@media screen and (max-width: 320px) {
  .card {
    max-width: 300px;
  }
}
</style>
