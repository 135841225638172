<template>
  <div class="schedule">
    <div class="filter-title">Редактировать расписание</div>
    <div class="grid-container create-schedule">
      <div class="card">
        <div class="filter-title">Выберите дату</div>
        <Calendar v-model="selectedDate" selectionMode="single" :minDate="minDate" :manualInput="false"
          dateFormat="yy-mm-dd" inline @change="fetchSchedule" />
      </div>

      <div v-if="scheduleData.length > 0">
        <div class="card" v-for="master in scheduleData" :key="master.master.id">
          <div class="filter-title">
            {{ master.master.firstName }} {{ master.master.lastName }}
          </div>

          <div class="day-toggle">
            <label class="checkbox-container" :for="`master-${master.master.id}-working`">
              <input class="custom-checkbox" type="checkbox" :id="`master-${master.master.id}-working`"
                v-model="master.workingDay" @change="updateWorkingDay(master)" />
              <span class="checkmark"></span>
              Рабочий день
            </label>
          </div>

          <div class="time-selector" v-show="master.workingDay">
            <div v-for="(timeSlot, index) in master.timeSlots" :key="`${master.master.id}-${index}`" class="time-entry">
              <InputNumber v-model="timeSlot.hours" :min="0" :max="23" size="2" hideButton class="time-input" />
              :
              <InputNumber v-model="timeSlot.minutes" :min="0" :max="59" size="2" hideButton class="time-input" />
              <svg @click="deleteTime(master, index)" viewBox="0 0 30 30" fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                <g id="SVGRepo_iconCarrier">
                  <path
                    d="M12.0004 9.5L17.0004 14.5M17.0004 9.5L12.0004 14.5M4.50823 13.9546L7.43966 17.7546C7.79218 18.2115 7.96843 18.44 8.18975 18.6047C8.38579 18.7505 8.6069 18.8592 8.84212 18.9253C9.10766 19 9.39623 19 9.97336 19H17.8004C18.9205 19 19.4806 19 19.9084 18.782C20.2847 18.5903 20.5907 18.2843 20.7824 17.908C21.0004 17.4802 21.0004 16.9201 21.0004 15.8V8.2C21.0004 7.0799 21.0004 6.51984 20.7824 6.09202C20.5907 5.71569 20.2847 5.40973 19.9084 5.21799C19.4806 5 18.9205 5 17.8004 5H9.97336C9.39623 5 9.10766 5 8.84212 5.07467C8.6069 5.14081 8.38579 5.2495 8.18975 5.39534C7.96843 5.55998 7.79218 5.78846 7.43966 6.24543L4.50823 10.0454C3.96863 10.7449 3.69883 11.0947 3.59505 11.4804C3.50347 11.8207 3.50347 12.1793 3.59505 12.5196C3.69883 12.9053 3.96863 13.2551 4.50823 13.9546Z"
                    stroke="#f4bc83" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                </g>
              </svg>
            </div>
            <Button @click="addTime(master)">Добавить время</Button>
          </div>
          <div class="buttons" v-if="scheduleData.length > 0">
            <ButtonComponent :name="'Сохранить'" @click="sendRequest(master)" />
          </div>
        </div>
      </div>
    </div>
    <ModalInfo v-if="isModalVisible" :message="modalMessage" @close="isModalVisible = false" />
  </div>
</template>

<script>
import axios from "axios";
import Calendar from "primevue/calendar";
import InputNumber from "primevue/inputnumber";
import ButtonComponent from "@/components/ButtonComponent.vue";
import Button from "primevue/button";
import ModalInfo from "@/components/ModalInfo.vue";

export default {
  components: {
    Calendar,
    InputNumber,
    ButtonComponent,
    Button,
    ModalInfo,
  },

  data() {
    return {
      selectedDate: null,
      minDate: new Date(),
      scheduleData: [],
      isModalVisible: false,
      modalMessage: "",
    };
  },
  watch: {
    selectedDate: {
      immediate: false,
      handler(newDate) {
        if (newDate) {
          this.fetchSchedule();
        } else {
          this.scheduleData = [];
        }
      },
    },
  },
  methods: {
    fetchSchedule() {
      const token = localStorage.getItem("token");
      const headers = {
        Authorization: `Bearer ${token}`,
      };

      const year = this.selectedDate.getFullYear();
      const month = this.selectedDate.getMonth() + 1;
      const day = this.selectedDate.getDate();

      axios
        .get(
          `https://leon-back-hiss.amvera.io/schedule/day?year=${year}&month=${month}&day=${day}`,
          { headers }
        )
        .then((response) => {
          this.scheduleData = response.data.map((master) => ({
            ...master,
            workingDay:
              master.timeSlots.length > 0 &&
              master.timeSlots.some((slot) => slot.working),
            timeSlots: master.timeSlots.map((timeSlot) => ({
              ...timeSlot,
              hours: parseInt(timeSlot.time.slice(0, 2)),
              minutes: parseInt(timeSlot.time.slice(3, 5)),
            })),
          }));
        })
        .catch((error) => {
          console.error("Ошибка получения расписания:", error);
        });
    },
    addTime(master) {
      master.timeSlots.push({
        hours: 12,
        minutes: 0,
        working: true,
      });
    },
    deleteTime(master, index) {
      master.timeSlots.splice(index, 1);
    },
    updateWorkingDay(master) {
      const masterId = master.master.id;
      const isWorking = master.workingDay;
      const formattedDate = this.formatDate(this.selectedDate);

      const token = localStorage.getItem("token");
      const headers = {
        Authorization: `Bearer ${token}`,
      };

      axios
        .put(
          `https://leon-back-hiss.amvera.io/schedule/update?masterId=${masterId}&isWorking=${isWorking}`,
          {
            dates: [formattedDate],
            times: isWorking
              ? master.timeSlots.map(
                (slot) =>
                  `${slot.hours.toString().padStart(2, "0")}:${slot.minutes
                    .toString()
                    .padStart(2, "0")}`
              )
              : [],
          },
          { headers }
        )
        .then(() => {
          console.log(
            `День ${formattedDate} для мастера ${masterId} ${isWorking ? "открыт" : "закрыт"
            }`
          );
          this.showModal("Данные успешно отправлены");
        })
        .catch((error) => {
          console.error("Ошибка обновления рабочего дня", error);
          // Обработка ошибки, например, откат состояния checkbox
          master.workingDay = !isWorking;
          this.showModal("Ошибка при отправке данных");
        });
    },
    sendRequest(master) {
      const formattedDate = this.formatDate(this.selectedDate);
      const formattedTimes = master.timeSlots.map(
        (timeSlot) =>
          `${timeSlot.hours.toString().padStart(2, "0")}:${timeSlot.minutes
            .toString()
            .padStart(2, "0")}`
      );

      const data = {
        dates: [formattedDate],
        times: master.workingDay ? formattedTimes : [],
      };

      const token = localStorage.getItem("token");
      const headers = {
        Authorization: `Bearer ${token}`,
      };

      axios
        .put(
          `https://leon-back-hiss.amvera.io/schedule/update?masterId=${master.master.id}&isWorking=${master.workingDay}`,
          data,
          { headers }
        )
        .then((response) => {
          console.log("Расписание обновлено", response);
          this.showModal("Данные успешно отправлены");
        })
        .catch((error) => {
          console.error("Ошибка обновления расписания", error);
          this.showModal("Ошибка при отправке данных");
        });
    },
    formatDate(date) {
      const year = date.getFullYear();
      const month = (date.getMonth() + 1).toString().padStart(2, "0");
      const day = date.getDate().toString().padStart(2, "0");
      return `${year}-${month}-${day}`;
    },
    showModal(message) {
      this.modalMessage = message;
      this.isModalVisible = true;
      setTimeout(() => {
        this.isModalVisible = false;
        this.modalMessage = "";
      }, 2000);
    },
  },
};
</script>

<style scoped>
.card {
  background-color: rgba(56, 56, 56, 0.6);
  backdrop-filter: blur(30px);
  border: 1px solid #ddd;
  padding: 20px;
  margin-bottom: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.filter-title {
  font-size: 22px;
  color: #f4bc83;
  margin-bottom: 20px;
  font-weight: bold;
}

.time-selector {
  /* display: flex;
  flex-wrap: wrap;
  justify-content: space-between; */
  margin-top: 20px;
}

.time-entry {
  margin-bottom: 10px;
  display: flex;
  align-items: center;
}

.time-entry InputNumber {
  margin-right: 10px;
  width: 100px;
}

.time-entry Button {
  margin-left: 10px;
  background-color: #f4bc83;
  color: #fff;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
}

.time-entry Button:hover {
  background-color: #f7bc83;
}

.buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  margin-bottom: 20px;
}

.buttons Button {
  background-color: #f4bc83;
  color: #fff;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
}

.buttons Button:hover {
  background-color: #f7bc83;
}

select {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #2a2a2a;
  color: #fff;
  appearance: none;
  margin-top: 10px;
}

select:focus {
  border-color: #f4bc83;
  outline: none;
}

option {
  background-color: #2a2a2a;
  color: #fff;
}

option:checked,
option:hover {
  background-color: #f4bc83;
}

.calendar-container {
  text-align: center;
  margin-top: 20px;
}

.calendar {
  width: 100%;
  height: 300px;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 20px;
}

.p-calendar {
  width: 100%;
}

svg {
  width: 40px;
  height: 40px;
  cursor: pointer;
}

.toggle-container {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.toggle-label {
  margin-right: 10px;
  color: #fff;
}

.toggle {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 30px;
}

.toggle input {
  opacity: 0;
  width: 0;
  height: 0;
}

.toggle label {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  border-radius: 30px;
  transition: 0.4s;
}

.toggle label:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  left: 5px;
  bottom: 5px;
  background-color: white;
  border-radius: 50%;
  transition: 0.4s;
}

.toggle input:checked+label {
  background-color: #f4bc83;
}

.toggle input:checked+label:before {
  transform: translateX(30px);
}

.checkbox-container {
  display: inline-block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 16px;
  user-select: none;
}

.custom-checkbox {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #eee;
  border-radius: 4px;
  transition: background-color 0.3s;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
}

.custom-checkbox:checked~.checkmark {
  background-color: #f4bc83;
  box-shadow: 0 3px 7px rgba(244, 188, 131, 0.3);
}

.custom-checkbox:checked~.checkmark:after {
  display: block;
}

@keyframes checkAnim {
  0% {
    height: 0;
  }

  100% {
    height: 10px;
  }
}

.custom-checkbox:checked~.checkmark:after {
  animation: checkAnim 0.2s forwards;
}

@media screen and (max-width: 320px) {
  .card {
    max-width: 300px;
  }
}
</style>
