<template>
  <div class="admin-container">
    <ul class="nav nav-tabs" v-if="!isMobileView">
      <li class="nav-item"><a class="nav-link" href="/">На сайт</a></li>
      <li class="nav-item" v-for="(item, index) in visibleNavItems" :key="index">
        <a
          href="#"
          class="nav-link"
          @click.prevent="handleItemClick(item.tab)"
          :class="{ active: activeTab === item.tab }"
        >
          {{ item.label }}
        </a>
      </li>
      <li class="nav-item"><a class="nav-link" href="/admin" @click="logout">Выход из админки</a></li>
    </ul>

    <button class="setting-btn" @click="toggleMenu()" v-if="isMobileView">
      <span class="bar bar1"></span>
      <span class="bar bar2"></span>
      <span class="bar bar3"></span>
    </button>

    <div :class="['container-nav-small', { open: isOpen }]" v-if="isMobileView">
      <ul class="nav-list-mini">
        <li class="li-list-mini"><a class="link" href="/">На сайт</a></li>
        <li class="li-list-mini" v-for="(item, index) in visibleNavItems" :key="index">
          <a
            href="#"
            class="link"
            @click.prevent="handleItemClick(item.tab)"
            :class="{ active: activeTab === item.tab }"
          >
            {{ item.label }}
          </a>
        </li>
        <li class="li-list-mini"><a class="link" href="/admin" @click="logout">Выход из админки</a></li>
      </ul>
    </div>

    <div class="tab-content">
      <component :is="activeComponent" />
    </div>
  </div>
</template>

<script>
import MasterCreate from "@/components/MasterCreate.vue";
import Appointments from "@/components/Appointments.vue";
import ScheduleAdmin from "@/components/ScheduleAdmin.vue";
import CreateAppointments from "@/components/CreateAppointments.vue";
import CreateHomeCare from "@/components/CreateHomeCare.vue";
import BlackList from "@/components/BlackList.vue"
import { jwtDecode } from "jwt-decode";
import Statistics from "@/components/Statistics.vue";

export default {
  name: "AdminPage",
  components: {
    MasterCreate,
    Appointments,
    ScheduleAdmin,
    CreateAppointments,
    CreateHomeCare,
    BlackList,
    Statistics,
  },
  data() {
    return {
      activeTab: "appointments", 
      isAdmin: false,
      isOpen: false,
      isMobileView: window.innerWidth <= 768, // Проверяем размер экрана
      navItems: [
        { label: "Записи", tab: "appointments" },
        { label: "Расписание", tab: "schedule", isAdmin: true },
        { label: "Мастера", tab: "masters", isAdmin: true },
        { label: "Создать запись", tab: "createAppointments" },
        {label: "Чёрный список", tab: "blackList"},
        {label: "Статистика", tab: "statistics"}
        // { label: "Домашний уход", tab: "homeCare" },
      ],
    };
  },
  created() {
    this.checkAdminRole();
    window.addEventListener('resize', this.handleResize); // Слушаем изменение размера окна
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize); // Удаляем слушатель перед уничтожением компонента
  },
  computed: {
    activeComponent() {
      switch (this.activeTab) {
        case "appointments":
          return Appointments;
        case "schedule":
          return ScheduleAdmin;
        case "masters":
          return MasterCreate;
        case "createAppointments":
          return CreateAppointments;
        case "homeCare":
          return CreateHomeCare;
        case "blackList":
          return BlackList;
        case "statistics":
          return Statistics;
        default:
          return null;
      }
    },
    visibleNavItems() {
      return this.navItems.filter(item => !item.isAdmin || this.isAdmin);
    }
  },
  methods: {
    checkAdminRole() {
      const token = localStorage.getItem("token");
      if (token) {
        const decodedToken = jwtDecode(token);
        this.isAdmin =
          decodedToken.role && decodedToken.role[0].authority === "ADMIN";
      }
    },
    toggleMenu() {
      this.isOpen = !this.isOpen;
    },
    handleItemClick(tab) {
      this.activeTab = tab;
      this.isOpen = false; 
    },
    handleResize() {
      this.isMobileView = window.innerWidth <= 768;
    },
    logout() {
      localStorage.removeItem("token");
    }
  },
};
</script>

<style scoped>
.admin-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.nav-tabs {
  display: flex; /* По умолчанию отображаем меню */
  justify-content: center;
}

.setting-btn {
  display: none;
}

.tab-content {
  display: grid;
  justify-content: center;
}

.nav {
  display: flex;
  justify-content: center;
}

.nav-item {
  list-style-type: none;
  margin: 20px;
  font-size: 20px;
}

.nav-link {
  text-decoration: none;
}

.container-nav-small {
  display: none;
}

@media screen and (max-width: 1440px) {
  .li-list {
    margin-right: 60px;
  }
}

@media screen and (max-width: 1024px) {
  .li-list {
    margin-right: 30px;
  }
}

@media screen and (max-width: 768px) {
  .li-list {
    margin-right: 20px;
    font-size: 22px;
  }
}

@media screen and (max-width: 712px) {
  .li-list {
    margin-right: 20px;
    font-size: 18px;
  }
}

@media screen and (max-width: 640px) {
  .setting-btn {
    display: flex; /* Отображаем кнопку на мобильных */
  }
  .nav-tabs {
    display: none;
  }
  .container-nav-small {
    position: fixed;
    top: 0;
    right: -50%;
    width: 50%;
    height: 100%;
    background-color: rgba(56, 56, 56, 0.6);
    backdrop-filter: blur(30px);
    box-shadow: -2px 0 5px rgba(0, 0, 0, 0.5);
    transition: right 0.3s ease;
    z-index: 1000; /* Убедитесь, что меню находится выше других элементов */
    display: flex;
    flex-direction: column;
  }

  .nav-list-mini {
    list-style: none;
    padding: 0;
    margin: 0;
    width: 100%;
  }

  .li-list-mini {
    padding: 15px;
    /* border-bottom: 1px solid #ddd; */
    margin-bottom: 20px;
  }

  .link {
    text-decoration: none;
    color: #f4bc83;
    display: block;
    font-size: 22px;
  }

  .container-nav-small.open {
    right: 0;
  }

  #toggleButton {
    position: fixed;
    top: 20px;
    right: 20px;
    z-index: 1001; /* Убедитесь, что кнопка находится выше меню */
    padding: 10px 20px;
    background-color: #333;
    color: #fff;
    border: none;
    cursor: pointer;
  }

  ul {
    flex-direction: column;
  }

  .setting-btn {
    width: 45px;
    height: 45px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 6px;
    background-color: #f4bc83;
    border-radius: 10px;
    cursor: pointer;
    border: none;
    box-shadow: 0px 0px 0px 2px rgba(244, 188, 131, 0.4);
    z-index: 1001;
    margin-right: 10px;
    position: fixed;
    right: 0px;
    top: 10px;
  }
  .bar {
    width: 50%;
    height: 2px;
    background-color: #383838;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    border-radius: 2px;
  }
  .bar::before {
    content: "";
    width: 2px;
    height: 2px;
    background-color: rgb(126, 117, 255);
    position: absolute;
    border-radius: 50%;
    border: 2px solid #383838;
    transition: all 0.3s;
    box-shadow: 0px 0px 5px white;
  }
  .bar1::before {
    transform: translateX(-4px);
  }
  .bar2::before {
    transform: translateX(4px);
  }
  .setting-btn:hover .bar1::before {
    transform: translateX(4px);
  }
  .setting-btn:hover .bar2::before {
    transform: translateX(-4px);
  }
}

@media screen and (max-width: 480px) {
  .logo {
    width: 80px;
    height: 90px;
    margin-left: 10px;
  }

  .li-list {
    font-size: 26px;
  }
}
</style>
