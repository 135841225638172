<template>
  <div class="statistics-container">
    <h1>Статистика</h1>
    <div class="charts">
      <div class="chart-block">
        <h2>Количество записей (с января по декабрь)</h2>
        <Chart type="line" :data="appointmentsChartData" :options="chartOptions" class="chart" />
      </div>
      <div class="chart-block">
        <h2>Заработок мастера по месяцам</h2>
        <Chart type="line" :data="earningsChartData" :options="chartOptions" class="chart" />
      </div>
    </div>
  </div>
</template>

<script>
import Chart from 'primevue/chart';
export default {
  data() {
    return {
      appointmentsChartData: null,
      earningsChartData: null,
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            labels: {
              color: '#495057'
            }
          }
        },
        scales: {
          x: {
            ticks: {
              color: '#495057'
            },
            grid: {
              color: '#ebedef'
            }
          },
          y: {
            ticks: {
              color: '#495057'
            },
            grid: {
              color: '#ebedef'
            }
          }
        }
      }
    };
  },
  mounted() {
    this.initializeCharts();
  },
  methods: {
    initializeCharts() {
      // Месяца с января по декабрь
      const months = ['Янв', 'Фев', 'Мар', 'Апр', 'Май', 'Июн', 'Июл', 'Авг', 'Сен', 'Окт', 'Ноя', 'Дек'];

      // Случайные данные для количества записей
      const appointmentsData = months.map(() => Math.floor(Math.random() * 100));

      // Случайные данные для заработка мастера (например, в тысячах рублей)
      const earningsData = months.map(() => Math.floor(Math.random() * 5000));

      this.appointmentsChartData = {
        labels: months,
        datasets: [
          {
            label: 'Количество записей',
            data: appointmentsData,
            fill: false,
            borderColor: '#42A5F5',
            tension: 0.4
          }
        ]
      };

      this.earningsChartData = {
        labels: months,
        datasets: [
          {
            label: 'Заработок мастера',
            data: earningsData,
            fill: false,
            borderColor: '#66BB6A',
            tension: 0.4
          }
        ]
      };
    }
  }
};
</script>

<style scoped>
.statistics-container {
  padding: 1rem;
  max-width: 1200px;
  margin: 0 auto;
}

.charts {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  margin-top: 1rem;
}

.chart-block {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  padding: 1.5rem;
  transition: transform 0.3s ease;
}

.chart-block:hover {
  transform: translateY(-5px);
}

.chart {
  height: 300px;
}

/* Адаптация для телефонов */
@media (max-width: 767px) {
  .statistics-container {
    padding: 0.5rem;
    max-width: 100%;
  }
  
  .chart {
    height: 250px;
  }
}

/* Адаптация для планшетов */
@media (min-width: 768px) {
  .charts {
    flex-direction: row;
    flex-wrap: wrap;
  }
  .chart-block {
    flex: 1 1 calc(50% - 2rem);
  }
}

/* Адаптация для ПК и ноутбуков */
@media (min-width: 1024px) {
  .chart {
    height: 400px;
  }
}
</style>